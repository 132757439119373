<template>
  <CommonButton
    v-if="!emailSend"
    bg="bg-blue"
    text="text-white"
    border="border-none"
    @click="sendActivationEmail"
  >
    {{ $t('ResendActivationMail') }}
  </CommonButton>
  <div v-else class="h-9 leading-9">
    {{ $t('EmailSendt') }}
  </div>
</template>

<script setup lang="ts">
const userStore = useUserStore()
const config = useRuntimeConfig()
const nuxtApp = useNuxtApp()
const requestUrl = useRequestURL()

const emailSend = ref(false)

async function sendActivationEmail() {
  if (!userStore.user) {
    return
  }

  if (userStore.user.userGuid) {
    try {
      await nuxtApp.$api.user.sendConfirmationEmailJwt(
        `${config.public.site.domainfull}`,
        userStore.user.email,
        requestUrl.href
      )

      emailSend.value = true
    } catch (error) {
      console.error('Error in sendConfirmationEmailJwt:', error)
    }
  } else {
    try {
      await nuxtApp.$api.user.sendConfirmationEmail(
        userStore.user.userId,
        `${requestUrl.href}?o=o`, // HACK! backend is not handling urls correctly have to add a ? to make it work
        'standart'
      )
      emailSend.value = true
    } catch (error) {
      console.error('Error in resendActivationMail:', error)
    }
  }
}
</script>
